<template>
  <app-form-view
    app="liability"
    model="liabilitygroup"
    api-url="liability/liability-group/"
    :title="$t('menu.liabilityGroup')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepareData="prepareData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="company"
            type="select-server"
            rules="required"
            :label="$t('fields.companyName')"
            :view="view"
            :binds="{
              apiUrl: 'contact/contact/?contact_type=company'
            }"
            v-model="formData.company"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveCompany"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_company"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.liabilityGroupName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>

      <v-card>
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>{{ $t('menu.description') }}</v-tab>
          <v-tab v-if="mode !== 'create'">{{ $t('menu.liability') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-input
                  name="Description"
                  type="html-editor"
                  :label="$t('fields.description')"
                  :view="view"
                  v-model="formData.description"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create'">
            <app-table
              server-side
              ref="liability_table"
              app="liability"
              model="liability"
              hide-edit
              :hide-delete="mode !== 'edit'"
              :headers="liabilityHeaders"
              :server-items="liabilities"
              :server-items-length="liabilitiesCount"
              :is-selecteable="false"
              :loading="loading"
              @server="getLiabilities"
              @delete="onDeleteLiability"
            >
              <template v-slot:item.related_to="{ value }">
                <app-input
                  type="chips"
                  name="related_to"
                  class="w-100"
                  :items="relatedToItems"
                  :value="value"
                  label=""
                  view
                >
                  <template v-slot="{ value }">{{ value.name }} </template>
                </app-input>
              </template>
              <template v-slot:item.related_parties="{ value }">
                <app-input
                  type="chips"
                  name="related_parties"
                  class="w-100"
                  :items="related_parties"
                  :value="value"
                  label=""
                  view
                >
                  <template v-slot="{ value }">{{ value.name }} </template>
                </app-input>
              </template>
            </app-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput.vue'
import AppTable from '@components/AppTable.vue'
import { defaultTableParams } from '@utils/app-table'
import relatedToItems from '@composables/asset/relatedToItems'

export default {
  name: 'liability-group-form',
  components: {
    AppTable,
    AppInput,
    AppFormView
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: ['approve_company', 'approve_name'],
      loading: false,
      tab: null,
      liabilities: [],
      liabilitiesCount: 0
    }
  },
  computed: {
    liabilityGroupId() {
      return this.$route.params.id
    },
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    liabilityHeaders() {
      return [
        {
          text: this.$t('fields.liabilityName'),
          value: 'name',
          hideFilter: true
        },
        {
          text: this.$t('fields.relatedParty'),
          value: 'related_parties',
          hideFilter: true
        },
        {
          text: this.$t('fields.relatedTo'),
          value: 'related_to',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.usingStatus'),
          value: 'using_status',
          sortable: false,
          hideFilter: true,
          check: true
        },
        {
          text: this.$t('fields.validityStatus'),
          value: 'validity_status',
          sortable: false,
          hideFilter: true,
          check: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    relatedToItems
  },
  methods: {
    prepareData(data) {
      data.company = data.company.id
      return data
    },

    async getLiabilities(options = null) {
      if (!this.liabilityGroupId) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.liability_group = this.liabilityGroupId

        const { data } = await this.$api({
          method: 'get',
          url: `liability/liability/`,
          params: params,
          hideSuccessAlert: true
        })
        this.liabilities = data.results
        this.liabilitiesCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDeleteLiability(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `liability/liability/`,
          data: {
            pks: items.map((value) => value.id)
          }
        })
          .then(() => {
            this.$refs.liability_table.onServer()
          })
          .finally(() => {
            this.loading = false
          })
      })
    }
  }
}
</script>
